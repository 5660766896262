'use strict'

###*
 # @ngdoc filter
 # @name mundoUtils.filters:IsEmptyObjectFilter

 # @description

###
angular
  .module 'mundoUtils'
  .filter 'isEmptyObject',
    () ->
      (obj) ->
        return Object.keys(obj).length

